.stake-main{
    @apply flex flex-col justify-center items-center min-h-full;
    padding: 20px 0;
}

.stake-main .global-page-header.v1 {
    margin-bottom: 0rem;
    min-height: 100px;
}

.stake-main .global-page-header .title {
    font-size: 2rem;
}

.stake-box{
    @apply rounded-lg mx-auto;
    width: 100%;
    max-width: 432px;
    background: #0D0805;
    border: 1px solid rgb(255 255 255 / 7%);
}
.stake-box .header{
    @apply flex justify-between;
    padding: 10px 10px 0px 10px;
    border-radius: 8px 8px 0px 0px;
}
.stake-box .header .stacking-tabs{
    @apply flex items-center gap-x-3;
}
.stake-box .header .stacking-tabs .tab-item{
    @apply leading-none md:leading-none m-0;
    color: var(--text-dark-500);
    margin: 0px;
    display: flex;
    align-items: center;
    font-size: 1rem;
    line-height: 1.25rem;
    padding: 8px 12px 8px 12px;
}
.stake-box .header .stacking-tabs .tab-item.active{
    color: var(--text-white-200);
    background-color: #161210;
    padding: 8px 12px 8px 12px;
    border-radius: 10px;
}
.stake-box .header .btn-settings{
    @apply px-1 py-1;
}

.stake-box .body {
    @apply px-3 py-3
}

.stake-box .body .enter-balance{
    @apply relative flex items-center px-2 gap-1 justify-between flex-row mb-3.5;
    background: #F5F7FA0A;
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.04);
}

.stake-box .body .enter-balance .icon-1, .stake-box .body .enter-balance .icon-2 {
    min-width: 20px;
    width: 32px;
}

.stake-box .body .enter-balance .icon-2 {
   left: -5px;
}

.stake-box .body .enter-balance .left-info{
    @apply flex items-center flex-row gap-2 pr-4 justify-between;
    border-right: 1px solid rgba(255, 255, 255, 0.04);
    color: rgba(241, 225, 212, 1);
    height: 45px;
}
.stake-box .body .enter-balance .input{
    flex: 1;
}

.stake-box .body .enter-balance .field{
    @apply w-full px-2 shadow-none outline-0;
    height: 45px;
    width: 100%;
    font-size: 1.1rem;
    /* padding: 0 65px 0 60px; */
    color: var(--text-white-200);
    background: #17120f;
}
.stake-box .body .enter-balance .field:focus{
    /* border: 1px solid rgba(255, 255, 255, 0.04) !important; */
    /* outline: 1px solid rgba(255, 255, 255, 0.04) !important; */
}

.stake-box .body .enter-balance .field::placeholder{
    color: rgba(97, 106, 119, 0.5);
}
.stake-box .body .enter-balance .field::-webkit-input-placeholder {
    color: rgba(97, 106, 119, 0.5);
}
.stake-box .body .enter-balance .field::-moz-placeholder {
    color: rgba(97, 106, 119, 0.5);
}
.stake-box .body .enter-balance .field:-ms-input-placeholder {
    color: rgba(97, 106, 119, 0.5);
}
.stake-box .body .enter-balance .field:-moz-placeholder {
    color: rgba(97, 106, 119, 0.5);
}
.stake-box .body .enter-balance .tag{
    @apply w-11 h-6 flex items-center justify-center text-xs rounded-lg;
    /* transform: translateY(-50%); */
    /* font-weight: var(--fw-700); */
    color: var(--text-dark-300);
    padding: 5px 6px 5px 6px;
    border-radius: 3px;
    background-color: rgba(245, 247, 250, 0.04);
}
.stake-box .body .velar-balance{
    @apply text-base leading-none text-right mb-6;
    /* font-weight: var(--fw-400); */
    font-size: 0.96rem;
    color: rgba(245, 247, 250, 0.36);
}
.stake-box .body .will-recieve{
    @apply w-full flex items-center justify-between gap-x-4 px-4 py-3;
    border-radius: 8px;
    border: 1px solid #F5F7FA08;
    background-color: #17120f;
}
.stake-box .body .will-recieve .text{
    @apply text-sm md:text-base leading-none md:leading-none mb-0;
    /* font-weight: var(--fw-400); */
    font-size: 0.96rem;
    /* color: rgba(248, 180, 102, 1); */
}
.stake-box .body .will-recieve .value{
    @apply text-base md:text-lg leading-none md:leading-none mb-0;
    /* font-weight: var(--fw-600); */
    font-size: 0.96rem;
}

.stake-box .body .staking-details{
    @apply w-full;
    background-color: rgba(244, 244, 245, 0.03);
    border: 1px solid var(--alpha-50, rgba(245, 247, 250, 0.08));
    border-radius: 10px;
}
.stake-box .body .staking-details button {
    @apply flex items-center  p-2 justify-between gap-x-5 text-base;
    font-weight: var(--fw-500);
    color: var(--text-white-700);
    border-bottom: 1px solid var(--alpha-50, rgba(245, 247, 250, 0.08));
}

.stake-box .body .staking-details #staking-details .info {
    @apply p-3 flex items-center;
    border-bottom: 1px solid var(--alpha-50, rgba(245, 247, 250, 0.08));
}

.stake-box .body .staking-details #staking-details .info-no-border {
    @apply p-3 flex items-center;
}

.stake-box .body .staking-details #staking-details .key{
    @apply text-sm capitalize leading-none mb-0;
    font-weight: var(--fw-400);
}
.stake-box .body .staking-details #staking-details .value{
    @apply text-sm text-right leading-none mb-0;
    font-weight: var(--fw-400);
}
.stake-box .body .staking-details #staking-details hr{
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.my-rewards .block-info {
    background: rgba(255, 255, 255, 0.02);
    border: 1px solid rgba(255, 255, 255, 0.02);
    padding: 5px 8px 8px 8px;
    border-radius: 8px;
}

.my-rewards .block-info .block-btn {
    padding-bottom: 8px;
    margin-bottom: 8px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);
}

.my-rewards .block-info .block-btn .title {
    color: white;
    font-size: 14px;
    font-weight: 300;
}

.my-rewards .block-info .details {
    font-size: 13px;
    margin: 0px 6px 0px 6px;
}


.my-rewards .block-info .value {
    color: rgba(113, 113, 121, 1);
}


.my-rewards .block-info .block-btn .dotted-line {
    width: 40%;
    height: 2px;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23FFFFFF14' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

@media screen and (max-width: 767.98px) {
    .my-rewards .block-info .block-btn .dotted-line {
        width: 42%;
    }
}

@media screen and (max-width: 550.98px) {
    .my-rewards .block-info .block-btn .dotted-line {
        width: 15%;
    }
}

@media screen and (max-width: 400.98px) {
    .my-rewards .block-info .block-btn .dotted-line {
        width: 10%;
    }
}

.stake-token-amount {
    background-color: #F5F7FA0A;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    border-bottom: 1px solid #F5F7FA08
}

.stake-token-input {
    border-bottom: 1px solid #F5F7FA08;
    background-color: #F5F7FA0A;
}

.stake-token-input .input {
    width: 100%;
}

.stake-token-input .input input {
    width: 100%;
    color: white;
    font-size: 1.5rem;
    outline: unset;
    background-color: #17120f;
}

.stake-token-input .input input:focus {
    outline: unset;
}

.stake-token-input .input input::placeholder {
    color: #F5F7FA5C;
    opacity: 1; /* Firefox */
  }

.stake-token-balance {
    background-color: #F5F7FA0A;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-bottom: 1px solid #F5F7FA08
}