:root{
    --text-3xl-34: 34px;
    
    /* Start Font weight */
    --fw-300: 300;
    --fw-400: 400;
    --fw-500: 500;
    --fw-600: 600;
    --fw-700: 700;
    /* End Font weight */
    
    /* Start Font Colors */
    --text-white-100: #FEFEFF;
    --text-white-200: rgba(255, 255, 255, 0.92);
    --text-white-300: #F3F9FE;
    --text-white-400: #F4F4F4;
    --text-white-500: #E6E6E6;
    --text-white-600: #E7E7E8;
    --text-white-700: #B9BFCF;
    --text-white-800: rgba(255, 255, 255, 0.62);
    --text-white-900: #F8FAFC;
    --text-dark-100: #ffffffb3;
    --text-dark-200: rgba(175, 180, 192, 0.48);
    --text-dark-300: rgba(255, 255, 255, 0.5);
    --text-dark-400: #758191;
    --text-dark-500: #717179;
    --text-dark-600: rgba(155, 155, 155, 0.74);
    --text-warning-100: #FFD3A0;
    --text-warning-200: #FFE49E;
    --text-warning-300: #F89D33;
    --text-warning-400: #F8931F;
    --text-warning-500: #E4761B;
    --text-success-100: #9EFFCC;
    --text-danger-100: #FFA79B;
    --text-danger-200: rgba(255, 167, 155, 0.92);
    --text-danger-300: #FECCCC;
    --text-danger-400: #FF4848;
    /* End Font Colors */
    
    /* Start Background Colors */
    --bg-dark-100: rgba(248, 248, 249, 0.1);
    --bg-dark-200: rgba(248, 248, 249, 0.03);
    --bg-dark-300: rgba(255, 255, 255, 0.04);
    --bg-dark-400: rgba(240, 240, 242, 0.02);
    --bg-dark-500: rgba(15, 16, 17, 0.42);
    --bg-dark-600: rgba(8, 9, 10, 0.24);
    --bg-dark-700: #08090A;
    --bg-dark-800: rgba(255, 255, 255, 0.1);
    --bg-dark-900: rgba(248, 248, 250, 0.08);
    --bg-dark-1000: #0D0E0F;
    --bg-dark-1100: rgba(245, 245, 247, 0.02);
    --bg-dark-1200: rgba(245, 245, 247, 0.06);
    --bg-dark-1300: rgba(244, 244, 245, 0.03);
    --bg-dark-1400: rgba(255, 255, 255, 0.02);
    --bg-dark-1500: rgba(232, 233, 237, 0.13);
    --bg-dark-1600: rgba(244, 244, 246, 0.05);
    --bg-dark-1700: #261E1D;
    --bg-dark-1800: rgba(255, 255, 255, 0.03);
    --bg-dark-1900: rgba(13, 14, 17, 0.46);

    --bg-warning-100: #FAEFE3;
    --bg-warning-200: rgba(248, 157, 51, 0.19);
    --bg-warning-300: rgba(248, 147, 31, 0.13);
    --bg-warning-400: rgba(248, 157, 51, 0.05);
    --bg-warning-500: #493829;

    --bg-danger-100: rgba(255, 172, 172, 0.09);
    --bg-danger-200: rgba(254, 204, 204, 0.12);
    --bg-danger-300: #1E130C;
    --bg-danger-400: rgba(255, 72, 72, 0.12);

    --bg-success-100: rgba(35, 248, 31, 0.13);
     /* End Background Colors */
}

/* Start Main Layout */
.modal-opened{
    overflow: hidden;
    /* padding-right: 17px; */
}
.velar-app{
    min-height: 100vh;
    padding-top: 61px;
}
.text-dark{
    color: var(--text-dark-500) !important;
}
.text-warning{
    color: var(--text-warning-200) !important;
}
.text-success{
    color: var(--text-success-100) !important;
}
.btn-dark{
    @apply block w-full text-base;
    height: 60px;
    font-weight: var(--fw-700);
    color: var(--text-dark-200);
    background: var(--bg-dark-100);
    border: 1px solid rgba(248, 248, 249, 0.05);
    border-radius: 60px;
}
.btn-warning{
    @apply block w-full text-base;
    /* height: 60px; */
    font-weight: var(--fw-700);
    color: var(--text-warning-300);
    background: var(--bg-warning-200);
    border: 1px solid rgba(255, 223, 186, 0.05);
    border-radius: 60px;
}
.btn-rewards{
    @apply block w-full text-base;
    height: 40px;
    font-size: 12px;
    font-weight: var(--fw-300);
    color: rgba(248, 206, 157, 0.4);
    background: rgba(31, 25, 22, 1);
    border-radius: 6px;
}
.btn-danger{
    @apply block w-full text-base;
    height: 60px;
    font-weight: var(--fw-400);
    color: var(--text-danger-100);
    background: var(--bg-danger-100);
    border: 1px solid rgba(255, 172, 172, 0.05);
    border-radius: 11px;
}
.btn-insufficient{
    @apply block w-full text-base;
    height: 44px;
    /* font-weight: var(--fw-700); */
    color: rgba(245, 247, 250, 0.36);
    background: rgba(245, 247, 250, 0.03);
    border: 1px solid #F5F7FA08;
    padding: 12px 16px, 12px 16px;
    border-radius: 10px;
}
button.disabled {
    background-color: rgba(248, 157, 51, 0.45) !important;    ;
}
/* End Main Layout */

/* Start Global Page Header */
.global-page-header.v1 {
    @apply mb-4 mt-4 md:mb-9;
    border: unset !important;
    background-color: unset !important;
    border-radius: 22px;
    text-align: center;
    display: block !important;
}

.global-page-header {
    @apply mb-4 mt-4 md:mb-9;
    overflow: hidden;
    border: 1px solid #F5F7FA14;
    background-color: rgba(20, 13, 8, 1);
    border-radius: 22px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 137px;
    max-height: 137px;
}
.global-page-header .heading{
    min-width: 300px;
}
.global-page-header .image{
    position: relative;
    overflow: hidden;
}

.global-page-header .title{
    @apply leading-none mt-0 mb-2 md:mb-3;
    font-size: 24px;
    color: #fff;
    margin-left: 24px;
    /* font-weight: var(--fw-700); */
}
.global-page-header .desc{
    @apply text-base md:text-xl leading-none md:leading-none mb-0;
    font-size: 16px;
    margin-left: 24px;
    color: #F5F7FA80;
}
.header-searchbar{
    @apply text-sm shadow-none outline-0;
    height: 40px;
    border-radius: 10px;
    width: 260px;
    padding: 0 12px 0 40px;
    color: var(--text-white-200);
    background-color: var(--bg-dark-300);
    border: 1px solid #F5F7FA0A;
    background-image: url(/public/assets/icons/icon-searchbar-light.svg);
    background-repeat: no-repeat;
    background-position: left 14px center;
}
.header-searchbar:focus{
    box-shadow: none !important;
    outline: 0;
    border-color: #323232;
}
.header-searchbar::placeholder{
    color: rgba(255, 255, 255, 0.24);
}
.header-searchbar::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.24);
}
.header-searchbar::-moz-placeholder {
    color: rgba(255, 255, 255, 0.24);
}
.header-searchbar:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.24);
}
.header-searchbar:-moz-placeholder {
    color: rgba(255, 255, 255, 0.24);
}
/* End Global Page Header */

/* Start Max Width */
.max-w-1230{
    max-width: 1230px;
    width: 100%;
    margin: 0 auto;
}
.max-w-950{
    max-width: 950px;
    width: 100%;
    margin: 0 auto;
}
/* End Max Width */

@media screen and (max-width: 767.98px) {
    .btn-dark,
    .btn-warning,
    .btn-danger{
        height: 56px;
    }
    .global-page-header .title{
        font-size: 24px;
    }
}
.checkbox-container {
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.checkbox-container .checkbox {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkbox-container .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    border-radius: 5px;
    border: 1px solid rgba(59, 49, 45, 1);
    background-color: rgba(31, 26, 24, 1);
}

.checkbox-container:hover input ~ .checkmark {
    background-color: rgba(31, 26, 24, 1);
}

.checkbox-container input:checked ~ .checkmark {
    background-color: rgba(31, 26, 24, 1);
}


.checkbox-container .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.checkbox-container input:checked ~ .checkmark:after {
    display: block;
}


.checkbox-container .checkmark:after {
    left: 5px;
    top: -2px;
    width: 8px;
    height: 14px;
    border: solid rgb(146, 125, 117);
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.btn-new {
    height: 42px;
    padding: 12px 16px !important;
}

.slippage {
    display: flex;
    align-items: center;
}
.slippage .sl-text {
    display: flex;
    padding: 6px 8px;
    align-items: center;
    gap: 10px;
    border-radius: 20px 0px 0px 20px;
    border: 1px solid var(--alpha-25, rgba(245, 247, 250, 0.04));
    background: var(--alpha-0, rgba(245, 247, 250, 0.03));
    color: var(--alpha-500, rgba(245, 247, 250, 0.60));
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 12px */
}

.slippage .sl-btn {
    display: flex;
    padding: 6px 8px;
    align-items: center;
    cursor: pointer;
    gap: 10px;
    border-radius: 0px 20px 20px 0px;
    border-top: 1px solid var(--alpha-25, rgba(245, 247, 250, 0.04));
    border-right: 1px solid var(--alpha-25, rgba(245, 247, 250, 0.04));
    border-bottom: 1px solid var(--alpha-25, rgba(245, 247, 250, 0.04));
    background: var(--alpha-50, rgba(245, 247, 250, 0.08));
    color: var(--alpha-500, rgba(245, 247, 250, 0.60));
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 12px */
}

.global-page-header {

}

.table-velar {
    padding: 0px 15px 15px 15px;
    background-color: rgba(13, 8, 5, 1);
    border: 1px solid rgba(245, 247, 250, 0.04);
    border-radius: 22px;
    margin-bottom: 3em;
}

.color-velar {
    color: #F5F7FA5C;
}



@media screen and (max-width: 1024px) {
    .table-header {
        flex-direction: column;
        align-items: flex-start ;
        width: 100%;
    }
    .table-header .view-filters {
        width: 100%;
    }

    .table-header .view-filters form {
        width: 100%;
    }

    .table-header .view-filters .search-box {
        width: 100%;
    }
    
    .table-header .view-filters .search-box input {
        width: 100%;
    }


    .global-page-header {
        @apply mb-4 mt-4 md:mb-9;
        border: unset !important;
        background-color: unset !important;
        border-radius: 22px;
        text-align: left;
        display: block !important;
    }

    .global-page-header .desc {
        margin-left: 15px;
    }
    .global-page-header .title {
        margin-left: 15px;
    }
}

.color-orange {
    color: #F89728;
}

.tx-mobile-icon  {
    width: 60px !important;
    height: 60px !important;
}

.tx-mobile-icon img {
    width: 24px;
}

.btn-loader {
    border-radius: 10px;
    width: 100%;
    background-color: #F5F7FA0A;
    color: #F5F7FA80;
    font-size: 0.9rem;
}