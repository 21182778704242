.farm-details{
    @apply mx-auto py-5;
}

.farm-details .go-back-link{
    @apply flex items-center text-base mb-4 no-underline;
    font-weight: var(--fw-600);
    color: var(--text-white-200);
}
.farm-details .icons{
    @apply flex items-center;
}
.farm-details .icons img{
    @apply relative inline rounded-full;
    width: 44px;
    min-width: 45px;
}
.farm-details .icons img:nth-of-type(2){
    left: -15px;
}
.farm-details .coins{
    @apply flex items-center text-xl md:text-2xl leading-none md:leading-none mb-1;
    font-weight: var(--fw-600);
    color: var(--text-white-400);
}
.farm-details .fee{
    @apply text-sm leading-none mb-0;
    font-weight: var(--fw-600);
    color: var(--text-dark-500);
}

.token-icons .icons img {
    min-width: 30px !important;
    width: 30px !important;
}

.token-icons .icons {
    position: relative;
    left: 10px;
}

.token-icons .icons img:nth-of-type(2) {
    left: -8px;
}


.single-farm{
    @apply py-5;
}
.single-farm hr{
    @apply w-full h-0.5;
    background-color: rgba(255, 255, 255, 0.06);
}

.farm-info {
    width: 68%;
}

.farm-proccess-right {
    width: 32%;
}

@media screen and (max-width: 1024px) {
    .farm-proccess-right {
        width: 100%;
    }
    .pool-info {
        width: 100%;
    }
    .pool-column {
      flex-direction: column-reverse;
    }

    .pool-mobile-header {
        display: block;
    }
}

.farm-header .action-btns{
    @apply flex items-center justify-between md:justify-end gap-x-1.5 sm:gap-x-2;
}
.farm-header .action-btns .header-btn{
    @apply w-full md:w-auto text-xs whitespace-nowrap text-center;
    border-radius: 6px;
    /* font-weight: var(--fw-600); */
    color: #F5F7FA5C;
    padding: 4px 8px 4px 8px;
    background: var(--bg-dark-1800);
    border: 1px solid #F5F7FA08;
    cursor: default;
}





.single-farm .position-card-v2 {
    margin-top: 8px;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding: 12px !important;
    border-radius: 8px;
    background-color: #0D0805 !important;
    border: 1px solid rgba(255, 255, 255, 0.07);
}

.position-card-v2 .header .heading{
    color: var(--text-white-900);
    font-size: 16px !important;
}

.position-card-v2 .header .value{
    @apply text-sm leading-none mb-0;
    font-weight: var(--fw-200) !important;
    color: var(--text-dark-500);
    font-size: 16px !important;
}

.position-card-v2 .ps{
    padding: 12px;
    background-color: #F5F7FA08;
}

.position-card-v2 .ps1 {
    padding: 12px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    border-bottom: 1px solid #F5F7FA0A;
}

.position-card-v2 .ps2 {
    padding: 12px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

/* Start Pool - Postion Card */
.single-pool .position-card {
    @apply w-full py-5 rounded-lg;
    /* background-color: var(--bg-dark-700); */
}
.position-card .header{
    @apply flex items-center justify-between pb-4;
    /* border-bottom: 1px solid rgba(226, 232, 240, 0.05); */
}
.position-card .header .heading{
    @apply text-lg leading-none mb-0;
    color: var(--text-white-900);
}
.position-card .header .value{
    @apply text-xl leading-none mb-0;
    font-weight: var(--fw-600);
    color: var(--text-dark-500);
}
.position-card .coin .name{
    @apply text-sm leading-none mb-0;
    font-weight: var(--fw-500);
    color: var(--text-white-200);
}
.position-card .amount{
    @apply text-xs leading-none mb-0;
    font-weight: var(--fw-500);
    color: var(--text-dark-500);
}

.epoch-dropdown {
    cursor: pointer;
    background-color: #F5F7FA08;
    border: 1px solid #F5F7FA0A;
    border-radius: 8px;
    color: #F5F7FAF2;
    margin-bottom: 15px;
}

.epoch-dropdown > div {
    width: 100%;
}



/* Swap Action Modals */
.epochActionModal {
    backdrop-filter: blur(8px);
}

.epochActionModal .modal-footer {
    padding: 10px;
    border-top: 1px solid rgba(255, 255, 255, 0.06);
}

.epochActionModal .modal-footer .action {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
    gap: 22px;
}

.epochActionModal .modal-footer .action button {
    padding: 3px 12px;
    border: 1px solid #F5F7FA08;
    color: #F5F7FA14;
    border-radius: 5px;
    background: #F5F7FA08;
}

.epochActionModal .modal-footer .action button.active {
    padding: 3px 12px;
    color: #fff;
    border: 1px solid #F5F7FA08;
    border-radius: 5px;
    background: #F5F7FA08;
}

.epochActionModal .modal-header {
    padding: 12px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.06);
}

.epochActionModal .modal-content {
    @apply relative w-full rounded-lg;
    max-width: 432px;
    background-color: #0D0805;
    border: 1px solid rgba(255, 255, 255, 0.06);
}

.epochActionModal .modal-content .actions {
    padding: 10px;
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.06);
}

.epochActionModal .modal-content .filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.epochActionModal .modal-content .list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    max-height: 325px;
    overflow-y: auto;
}

.epochActionModal .modal-content .list .item {
    display: flex;
    padding: 15px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.06);
    justify-content: space-between;
    cursor: pointer;
    align-items: center;
    width: 100%;
    color: white;
}

.epochActionModal .modal-content .filters .btns {
    display: flex;
    gap: 10px;
    justify-content: space-between;
}

.epochActionModal .modal-content .filters .btns button {
    padding: 5px 10px 5px 10px;
    border-radius: 6px;
    background: linear-gradient(0deg, rgba(245, 247, 250, 0.04), rgba(245, 247, 250, 0.04)),
    linear-gradient(0deg, rgba(248, 151, 40, 0.04), rgba(248, 151, 40, 0.04));
    color: #F5F7FA5C;
    font-size: 0.85rem;
}


.epochActionModal .modal-content .actions input {
    width: 100%;
    color: white;
    font-size: 0.9rem;
    padding: 10px;
    background: linear-gradient(0deg, rgba(221, 223, 228, 0.07), rgba(221, 223, 228, 0.07)),
    linear-gradient(0deg, rgba(248, 248, 249, 0.03), rgba(248, 248, 249, 0.03));
    border-radius: 10px;
}

.epochActionModal .modal-content .actions input::placeholder {
    color: #F5F7FA5C;
}
.epochActionModal .modal-content .actions input:focus {
    border: none;
    outline: none;
}

.epochActionModal .modal-content .modal-title {
    font-size: 1rem;
    color: #F5F7FA99;
}
.epochActionModal .modal-content .progress-bar {
    padding: 1ex 10px;
    color: #F5F7FAD9;
    border-top: 1px solid #F5F7FA14;
    border-bottom: 1px solid #F5F7FA14;
    background-color: rgb(13 8 5 / 95%);
}

.epochActionModal .modal-content .progress-bar .disabled {
    color: #F5F7FA40;
}

.epochActionModal .modal-content .heading{
    @apply text-xl md:text-2xl leading-tight md:leading-tight text-center mb-0;
    color: var(--text-white-100);
}
.epochActionModal .modal-content .heading span{
    color: var(--text-warning-300);
}
.epochActionModal .modal-content .desc-lg{
    @apply text-lg md:text-xl text-center leading-snug md:leading-snug mb-0;
    font-weight: var(--fw-400);
    color: var(--text-white-200);
}
.epochActionModal .modal-content .desc-sm{
    @apply text-sm leading-tight text-center mb-0 mt-2;
    font-weight: var(--fw-600);
    color: var(--text-dark-500);
}
.epochActionModal .modal-content .desc-sm a{
    text-decoration: none;
    color: var(--text-warning-300);
}


@media screen and (max-width: 1024px) {
    .farm-proccess-right {
        width: 100%;
    }
    .farm-info {
        width: 100%;
    }
    .pool-column {
      flex-direction: column-reverse;
    }
    .farm-mobile-header {
        display: block;
    }
    .farm-top-header {
        display: none !important;
    }
}

.epoch-indicator {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: #F89728;
    display: inline-block;
    box-shadow: 0px -1px 1px 0px #00000030 inset;
    box-shadow: 0px 1px 1px 0px #FFFFFF40 inset;
    box-shadow: 0px 0px 0px 2px #523514;
}