.points-main{
	@apply pt-8 md:pt-9;
}
.points-main .page-items{
	@apply flex flex-col gap-y-8 md:gap-y-9;
}
.points-main .page-title{
	@apply leading-none mb-4;
	font-size: 36px;
	font-weight: var(--fw-500);
	color: var(--text-white-100);
}
.points-main .page-desc{
	@apply text-base md:text-lg leading-snug mb-0;
	font-weight: var(--fw-300);
	color: var(--text-dark-300);
}

.points-main hr{
	border-color: rgba(255, 255, 255, 0.07);
}

.points-main .points-stats-card{
	@apply flex items-center justify-between md:justify-end gap-x-3 md:gap-x-10;
}
.points-stats-card .stats-item:first-of-type{
	@apply me-auto;
}
.points-stats-card .stats-item .title{
	@apply leading-none mb-2 md:mb-2.5;
	font-size: 24px;
	font-weight: var(--fw-500);
	color: var(--text-white-100);
}
.points-stats-card .stats-item .desc{
	@apply text-base md:text-lg leading-snug mb-0;
	font-weight: var(--fw-300);
	color: var(--text-dark-300);
}
.points-stats-card .stats-item .key{
	@apply text-base leading-none capitalize whitespace-nowrap mb-2.5;
	font-weight: var(--fw-400);
	color: var(--text-dark-300);
}
.points-stats-card .stats-item .value{
	@apply text-xl leading-tight mb-0;
	font-weight: var(--fw-500);
	color: rgba(245, 247, 250, 0.90);
}

.points-main .points-leaderboard-card{
	@apply flex flex-col gap-y-3 p-3 rounded-xl;
	background-color: #0D0805;
	border: 1px solid rgba(245, 247, 250, 0.04);
}
.points-leaderboard-card .leaderboard-header{
	@apply flex md:items-center justify-between;
}
.points-leaderboard-card .leaderboard-header .heading{
	@apply text-lg leading-tight mb-0 ms-3;
	font-weight: var(--fw-500);
	color: rgba(245, 247, 250, 0.95);
}
.points-leaderboard-card .leaderboard-body{
	@apply overflow-x-auto;
	background-color: rgba(245, 247, 250, 0.04);
	border-radius: 10px;
}
.points-leaderboard-card .leaderboard-body table{
	@apply w-full;
}
.leaderboard-body table thead tr th{
	@apply text-base leading-tight text-start;
	padding: 16px 10px;
	color: rgba(245, 247, 250, 0.36);
	font-weight: var(--fw-400);
	white-space: nowrap;
}
.leaderboard-body table thead tr th:first-of-type{
	padding-left: 18px;
	border-top-left-radius: 10px;
}
.leaderboard-body table thead tr th:last-of-type{
	padding-right: 18px;
	border-top-right-radius: 10px;
}

.leaderboard-body table tbody tr td{
	@apply text-base leading-tight;
	padding: 16px 10px;
	color: rgba(245, 247, 250, 0.50);
	font-weight: var(--fw-400);
	white-space: nowrap;
	border-top: 1px solid rgba(245, 247, 250, 0.04);
}
.leaderboard-body table tbody tr td:first-of-type{
	padding-left: 18px;
}
.leaderboard-body table tbody tr td:last-of-type{
	padding-right: 18px;
	color: rgba(245, 247, 250, 0.85);
}
.leaderboard-body table tbody tr:nth-of-type(1) td:first-of-type{
	color: #FAA90D;
}
.leaderboard-body table tbody tr:nth-of-type(2) td:first-of-type{
	color: #C7DDF1;
}
.leaderboard-body table tbody tr:nth-of-type(3) td:first-of-type{
	color: #FE9345;
}
.leaderboard-body table tbody tr:last-of-type td:first-of-type{
	border-bottom-left-radius: 10px;
}
.leaderboard-body table tbody tr:last-of-type td:last-of-type{
	border-bottom-right-radius: 10px;
}
.leaderboard-body table tbody tr td .address{
	@apply flex items-center gap-x-2 no-underline;
}
.leaderboard-body table tbody tr td .address .text{
	@apply uppercase;
	color: rgba(245, 247, 250, 0.90);
}
.leaderboard-body table tbody tr td .address .badge{
	@apply hidden md:flex items-center h-6 text-xs px-2.5;
	color: rgba(245, 247, 250, 0.60);
	font-weight: var(--fw-300);
	background-color: rgba(245, 247, 250, 0.08);
	border: 1px solid rgba(245, 247, 250, 0.04);
	border-radius: 38px;
}

.points-leaderboard-card .btn-load-more{
	@apply text-sm leading-none h-10 w-full flex items-center justify-center border-0 outline-0 shadow-none;
	color: rgba(245, 247, 250, 0.50);
	font-weight: var(--fw-400);
	background-color: rgba(245, 247, 250, 0.04);
	border-radius: 10px;
}

.points-main .points-works-title{
	@apply text-3xl leading-none mb-4;
	font-weight: var(--fw-500);
	color: rgba(255, 255, 255, 0.88);
}
.points-accordion .accordion-item{
	border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.points-accordion .accordion-item .accordion-button{
	@apply leading-none w-full flex items-center justify-between py-5 border-0 outline-0 shadow-none;
	font-size: 22px;
	font-weight: var(--fw-400);
	color: rgba(255, 255, 255, 0.88);
}
.points-accordion .accordion-item .accordion-button img{
	width: 24px;
	transform: rotate(0deg);
	transition: all 0.3s;
}
.points-accordion .accordion-item .accordion-button.collapsed img{
	transform: rotate(-180deg);
}
.points-accordion .accordion-item .accordion-collapse{
	max-height: 0;
	overflow: hidden;
	transition: all 0.3s;
}
.points-accordion .accordion-item .accordion-collapse.show{
	max-height: 500px;
}
.points-accordion .accordion-item .accordion-body{
	padding: 0 0 24px
}
.points-accordion .accordion-item .accordion-body .text{
	@apply text-base leading-snug mb-0;
	font-weight: var(--fw-300);
	color: var(--text-dark-300);
}

@media screen and (max-width: 767.98px) {
	.points-main .page-title{
		font-size: 32px;
	}
	
	.points-main .points-stats-card{
		@apply flex-wrap gap-y-6;
	}
	.points-stats-card .stats-item:first-of-type{
		@apply w-full;
	}
	
	.points-leaderboard-card .leaderboard-header{
		@apply flex-col gap-y-4;
	}
	.points-leaderboard-card .leaderboard-header .search-box input{
		@apply w-full;
	}
	.leaderboard-body table thead tr th:first-of-type{
		padding-left: 10px;
	}
	.leaderboard-body table thead tr th:last-of-type{
		@apply text-end;
		padding-right: 10px;
	}
	
	.leaderboard-body table tbody tr td:first-of-type{
		padding-left: 10px;
	}
	.leaderboard-body table tbody tr td:last-of-type{
		@apply text-end;
		padding-right: 10px;
	}
	
	.points-accordion .accordion-item .accordion-button{
		font-size: 20px;
	}
	.points-accordion .accordion-item .accordion-collapse.show{
		max-height: 800px;
	}
	.points-accordion .accordion-item .accordion-body{
		padding: 0 0 20px
	}
}